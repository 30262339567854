var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Checkpoints"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Checkpoints"}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search checkpoints..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('router-link',{staticClass:"button is-rounded is-flex",attrs:{"to":{
                  name: 'create-checkpoint'
                }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Checkpoint")])],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.checkpoints.data),function(checkpoint){return _c('div',{key:checkpoint.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{staticClass:"mr-2",attrs:{"to":{
                name: 'edit-checkpoint',
                params: {
                  checkpoint: checkpoint.uuid
                }
              }}},[_vm._v(" "+_vm._s(checkpoint.instruction)+" ")]),_c('p',{staticClass:"has-text-grey"},[_vm._v("Last updated "+_vm._s(_vm._f("asCalendar")(checkpoint.updated_at)))])],1),_c('column',{staticClass:"is-2 is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"label":"Type","info":checkpoint.type.name}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"label":"Checklists","info":checkpoint.checklists_count}})],1)],1)],1)}),0),(_vm.checkpoints.data.length)?_c('pager',{attrs:{"pageable":_vm.checkpoints,"context":"Checkpoint","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.checkpoints.data.length,"with-link":"","heading":"There are no checkpoints available for display.","to":{
          name: 'create-checkpoint'
        },"to-text":"New Checkpoint"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }