<template>
  <loader v-bind="{ loading }" text="Loading Checkpoints">
    <columns>
      <column>
        <page-heading
          heading="Checkpoints"
        />
      </column>
      <column class="is-5">
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search checkpoints...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="$route.query.search"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
                <router-link 
                  class="button is-rounded is-flex" 
                  :to="{
                    name: 'create-checkpoint'
                  }">
                    <icon icon="plus"/>
                    <span>Checkpoint</span>
                  </router-link>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="checkpoint in checkpoints.data" :key="checkpoint.id">
            <columns>
              <column>
                <router-link class="mr-2" :to="{
                  name: 'edit-checkpoint',
                  params: {
                    checkpoint: checkpoint.uuid
                  }
                }">
                  {{ checkpoint.instruction }}
                </router-link>
                <p class="has-text-grey">Last updated {{ checkpoint.updated_at | asCalendar }}</p>
              </column>
              <column class="is-2 is-flex is-align-items-center is-justify-content-end">
                <index-info 
                    label="Type" 
                    :info="checkpoint.type.name"
                  />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <index-info label="Checklists" :info="checkpoint.checklists_count" />
              </column>
            </columns>
          </div>
        </div>

        <pager v-if="checkpoints.data.length" :pageable="checkpoints" context="Checkpoint" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="checkpoints.data.length"
          with-link
          heading="There are no checkpoints available for display."
          :to="{
            name: 'create-checkpoint'
          }"
          to-text="New Checkpoint"
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    },
    checkpoints: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadCheckpoints()
  },

  methods: {
    loadCheckpoints() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.checkpoints = data
        this.loading = false
      }, () => {
        this.loading = false
      }, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'checkpoints',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        name: 'checkpoints',
      });
    }
  },

  watch: {
    '$route': 'loadCheckpoints'
  }

}
</script>